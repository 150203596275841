import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import type { Game } from '../../shapes/games.types';
import { ReactiveFormsModule } from '@angular/forms';
import { GameSettingsFormComponent } from '../game-settings-form/game-settings-form.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-game-table-cell-settings',
  templateUrl: './game-table-cell-settings.component.html',
  styleUrl: './game-table-cell-settings.component.scss',
  imports: [ReactiveFormsModule, GameSettingsFormComponent],
})
export class GameTableCellSettingsComponent {
  public game = input.required<Game>();
}
