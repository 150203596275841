import { Inject, LOCALE_ID, Pipe, type PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';

@Pipe({ name: 'rtp', standalone: true, pure: true })
export class RtpFormat implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private readonly locale: string) {}

  transform(rtp: number | null): string {
    if (rtp == null) {
      return 'n/a';
    }

    return formatNumber(rtp * 100, this.locale, '1.2-2') + ' %';
  }
}
