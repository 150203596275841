import { ChangeDetectionStrategy, Component, effect, inject, type OnInit, viewChild, computed } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import type { Game } from '../shapes/games.types';
import { GamesSearchFormComponent } from './search-mask/games-search-form.component';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { SiteActionsComponent } from './site-actions/site-actions.component';
import { GamesTableComponent } from './games-table/games-table.component';
import { GamesSearchService } from '../services/games-search.service';
import { GamesDataService } from '../services/games-data.service';
import { GamesFetchService } from '../services/games-fetch.service';
import { GamesSiteService } from '../services/games-site.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'games',
  templateUrl: './games.component.html',
  styleUrls: ['./games.component.scss'],
  imports: [
    GamesTableComponent,
    GamesSearchFormComponent,
    SiteActionsComponent,
    MatCardModule,
    MatProgressBarModule,
    MatPaginatorModule,
  ],
})
export class GamesComponent implements OnInit {
  protected readonly gamesDataService = inject(GamesDataService);
  protected readonly gamesSearchService = inject(GamesSearchService);
  private readonly gamesFetchService = inject(GamesFetchService);
  private readonly gamesSiteService = inject(GamesSiteService);

  protected gamesDataSource = new MatTableDataSource<Game>();
  protected readonly paginator = viewChild.required(MatPaginator);

  protected readonly filteredGames = computed(() => this.gamesDataService.filteredGames());
  protected readonly loading = computed(() => this.gamesFetchService.loading());
  protected readonly currentSite = computed(() => this.gamesSiteService.currentSite());

  constructor() {
    effect(() => (this.gamesDataSource.paginator = this.paginator()));

    effect(() => (this.gamesDataSource.data = this.filteredGames()));
  }

  async ngOnInit() {
    this.gamesDataSource.sortingDataAccessor = (game, sortHeaderId) => {
      if (sortHeaderId === 'price') {
        return game.price.amountInMinor;
      }

      if (sortHeaderId === 'rtp') {
        return game.rtp;
      }

      if (sortHeaderId === 'general') {
        return game.displayName;
      }

      if (sortHeaderId === 'settings') {
        return game.priority;
      }

      return game.gameName;
    };

    await this.gamesFetchService.fetchAllSitesAndGames();
  }
}
