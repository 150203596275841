import { ChangeDetectionStrategy, Component, inject, input, effect, computed } from '@angular/core';
import { KeyValuePipe } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import type { Game } from '../../shapes/games.types';
import { GameState } from 'src/app/shapes/game-settings.types';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import type { GameSettingsForm } from 'src/app/shapes/game-settings-form.types';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { GamesSettingsService } from 'src/app/services/game-settings.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { GamesSiteService } from 'src/app/services/games-site.service';
import { GamesUtilsService } from 'src/app/services/games-utils.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-game-settings-form',
  templateUrl: './game-settings-form.component.html',
  styleUrl: './game-settings-form.component.scss',
  imports: [ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatInputModule, MatCheckboxModule, MatLabel],
})
export class GameSettingsFormComponent {
  private readonly gamesUtilsService = inject(GamesUtilsService);
  private readonly gamesSiteService = inject(GamesSiteService);
  protected readonly gamesSettingsService = inject(GamesSettingsService);

  public currentGame = input.required<Game>();

  protected readonly isSiteVisible = computed(() => this.gamesSiteService.isSiteVisible);

  protected currentGameActiveState = computed(() => {
    if (this.currentGame().gracefulShutdown) {
      return GameState.shutdown;
    }

    if (this.currentGame().active) {
      return GameState.active;
    }

    return GameState.disable;
  });

  protected gameState = GameState;

  protected gameSettingsForm: GameSettingsForm = new FormGroup({
    state: new FormControl<GameState>(GameState.active, { nonNullable: true }),
    isVisible: new FormControl(false, { nonNullable: true }),
    isNew: new FormControl(false, { nonNullable: true }),
    priority: new FormControl(0, { nonNullable: true }),
  });

  constructor() {
    effect(() => {
      // Input signal values supplied as initial form values.
      this.gameSettingsForm.setValue(
        {
          state: this.currentGameActiveState(),
          isVisible: this.currentGame().visible,
          isNew: this.currentGame().isNew,
          priority: this.currentGame().priority,
        },
        { emitEvent: false }
      );
    });

    this.gameSettingsForm.controls.state.valueChanges.pipe(takeUntilDestroyed()).subscribe(value => {
      this.gamesUtilsService.handleGameSettingsActions([this.currentGame(), { state: value }]);
    });

    this.gameSettingsForm.controls.isVisible.valueChanges.pipe(takeUntilDestroyed()).subscribe(value => {
      this.gamesUtilsService.handleGameSettingsActions([this.currentGame(), { isVisible: value }]);
    });

    this.gameSettingsForm.controls.isNew.valueChanges.pipe(takeUntilDestroyed()).subscribe(value => {
      this.gamesUtilsService.handleGameSettingsActions([this.currentGame(), { isNew: value }]);
    });

    this.gameSettingsForm.controls.priority.valueChanges
      .pipe(takeUntilDestroyed())
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe(value => {
        this.gamesSettingsService.syncAllSiteGamePrios(this.currentGame(), value);
      });
  }

  protected integrationTestId(field: string): string {
    return `input-${field}-${this.currentGame().gameName}-${this.currentGame().site}`;
  }
}
