import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { Game } from '../../shapes/games.types';
import { NgOptimizedImage } from '@angular/common';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-game-table-cell-general',
  templateUrl: './game-table-cell-general.component.html',
  styleUrl: './game-table-cell-general.component.scss',
  imports: [NgOptimizedImage],
})
export class GameTableCellGeneralComponent {
  @Input()
  public game!: Game;
}
