import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { Game } from '../../shapes/games.types';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-game-table-cell-values',
  templateUrl: './game-table-cell-values.component.html',
  styleUrl: './game-table-cell-values.component.scss',
  imports: [],
})
export class GameTableCellValuesComponent {
  @Input()
  public game!: Game;
}
