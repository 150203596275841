import { ChangeDetectionStrategy, Component, computed, effect, inject, input } from '@angular/core';
import type { Game, Site } from '../../shapes/games.types';
import { MatButtonModule } from '@angular/material/button';
import { GamesUtilsService } from 'src/app/services/games-utils.service';
import type { SiteActions } from '../../shapes/site-actions.types';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-site-actions',
  templateUrl: './site-actions.component.html',
  styleUrl: './site-actions.component.scss',
  imports: [MatButtonModule],
})
export class SiteActionsComponent {
  public currentSite = input.required<Site>();
  public games = input.required<Game[]>();

  protected readonly isMajorityOfSelectedGamesToggledActive = computed(() => {
    const games = this.games();
    return games.filter(game => game.active).length >= games.length / 2;
  });
  protected readonly isMajorityOfSelectedGamesToggledVisible = computed(() => {
    const games = this.games();
    return games.filter(game => game.visible).length >= games.length / 2;
  });
  protected readonly currentSiteName = computed(() => this.currentSite().name);
  private readonly gamesUtilsService = inject(GamesUtilsService);

  protected onSiteAction(siteActions: SiteActions) {
    void this.gamesUtilsService.handleSiteActions(this.currentSiteName(), siteActions);
  }
}
